import axios from 'axios';

const API_KEY = 'AIzaSyDKNWXeGFkQyHHfxa2p7uji_yIBju_8n68'; // Replace with your YouTube API key
const CHANNEL_ID = 'UCQQWZ1IeswjheSTSEXKcQsA'; // Replace with the specific YouTube channel ID

export const fetchVideosFromChannel = async () => {
  try {
    const response = await axios.get(`https://www.googleapis.com/youtube/v3/search`, {
      params: {
        key: API_KEY,
        channelId: CHANNEL_ID,
        part: 'snippet',
        maxResults: 10, // Adjust the number of results as needed
        order: 'date', // Sort by date
        type: 'video', // Only fetch videos, no playlists or other items
        videoDuration: 'long', // Only fetch videos longer than 10 minutes
      },
    });
    return response.data.items;
  } catch (error) {
    console.error('Error fetching videos:', error);
    return [];
  }
};
