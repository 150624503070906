import React, { useEffect, useState } from 'react';
import { fetchVideosFromChannel } from '../youtubeApi';
import '../styles/Videos.css';

const Videos = () => {
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    const getVideos = async () => {
      const videoData = await fetchVideosFromChannel();
      setVideos(videoData);
    };

    getVideos();
  }, []);

  return (
    <div className="video-container">
      {videos.map(video => (
        <div className="video-item" key={video.id.videoId}>
          <h3>{video.snippet.title}</h3>
          <img src={video.snippet.thumbnails.high.url} alt={video.snippet.title} />
          <p>{video.snippet.description}</p>
          <a
            href={`https://www.youtube.com/watch?v=${video.id.videoId}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Watch on YouTube
          </a>
        </div>
      ))}
    </div>
  );
};

export default Videos;
