import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Dashboard from './components/Dashboard';
import AnalyticsReports from './components/AnalyticsReports';
import Notifications from './components/Notifications';
import Navigation from './components/Navigation';
import ShowDetails from './components/ShowDetails';
import Faqs from './components/Faqs'; 
import UserInfo from './components/UserInfo'; // Import UserInfo component
import Videos from './components/Videos'; // Import Videos component
import Maintenance from './components/Maintenance'; // Import Maintenance component
import './App.css'; // Make sure to import the CSS file for styling

function App() {
  return (
    <Router>
      <div className="App">
        <Navigation />
        <div className="main-content"> {/* Apply the main-content class here */}
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/analytics" element={<AnalyticsReports />} />
            <Route path="/notifications" element={<Notifications />} />
            <Route path="/shows/:title" element={<ShowDetails />} />
            <Route path="/user-info" element={<UserInfo />} /> {/* Add route for user info */}
            <Route path="/faqs" element={<Faqs />} /> {/* Add this line */}
            <Route path="/videos" element={<Videos />} /> {/* Add this line */}
            <Route path="/maintenance" element={<Maintenance />} /> {/* Add route for maintenance */}
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
