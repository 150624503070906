import React, { useState, useEffect } from 'react';

const AnalyticsReports = () => {
    const [analytics, setAnalytics] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('http://localhost:5000/api/analytics');
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setAnalytics(data);
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (!analytics) {
        return <div>No analytics data available</div>;
    }

    return (
        <div>
            <h1>Analytics Reports</h1>
            <div>
                <h2>Description</h2>
                <p>{analytics.description || 'No description available'}</p>
            </div>
            <div>
                <h2>Viewership Data</h2>
                {analytics.viewershipData && analytics.viewershipData.length > 0 ? (
                    <ul>
                        {analytics.viewershipData.map((item, index) => (
                            <li key={index}>
                                {item.platform}: {item.views} views
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p>No viewership data available</p>
                )}
            </div>
            <div>
                <h2>Content Performance</h2>
                {analytics.contentPerformance && analytics.contentPerformance.length > 0 ? (
                    <ul>
                        {analytics.contentPerformance.map((item, index) => (
                            <li key={index}>
                                Content ID: {item.contentId}, Performance: {item.performance}
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p>No content performance data available</p>
                )}
            </div>
        </div>
    );
};

export default AnalyticsReports;
