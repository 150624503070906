import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../styles/Faqs.css'; // Import the CSS file

const Faqs = () => {
  const [faqs, setFaqs] = useState([]);
  const [newFaq, setNewFaq] = useState({ question: '', answer: '' });

  useEffect(() => {
    const fetchFaqs = async () => {
      try {
        const response = await axios.get('https://admin.minbertv.com/api/faqs');
        setFaqs(response.data);
      } catch (error) {
        console.error('Error fetching FAQs:', error);
      }
    };

    fetchFaqs();
  }, []);

  const handleChange = (e) => {
    setNewFaq({
      ...newFaq,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://admin.minbertv.com/api/faqs', newFaq);
      setFaqs([...faqs, response.data]);
      setNewFaq({ question: '', answer: '' });
    } catch (error) {
      console.error('Error adding FAQ:', error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`https://admin.minbertv.com/api/faqs/${id}`);
      setFaqs(faqs.filter(faq => faq._id !== id));
    } catch (error) {
      console.error('Error deleting FAQ:', error);
    }
  };

  return (
    <div className="faq-container">
      <h2>Frequently Asked Questions</h2>
      
      {/* Form to add new FAQ */}
      <form onSubmit={handleSubmit}>
        <div>
          <label>Question:</label>
          <input
            type="text"
            name="question"
            value={newFaq.question}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Answer:</label>
          <textarea
            name="answer"
            value={newFaq.answer}
            onChange={handleChange}
            required
          />
        </div>
        <button type="submit">Add FAQ</button>
      </form>

      {/* List of FAQs */}
      <ul>
        {faqs.map(faq => (
          <li key={faq._id}>
            <h3>{faq.question}</h3>
            <p>{faq.answer}</p>
            <button onClick={() => handleDelete(faq._id)}>Delete</button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Faqs;
