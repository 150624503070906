import React, { useEffect, useState } from 'react';
import '../styles/Dashboard.css';
import { useNavigate } from 'react-router-dom';

const Dashboard = () => {
    const [totalUsers, setTotalUsers] = useState(0);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    
    const API_URL = 'https://admin.minbertv.com/api'; // Directly defined here

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${API_URL}/users/count`);
                if (!response.ok) throw new Error('Network response was not ok');
                const usersData = await response.json();
                setTotalUsers(usersData.totalUsers);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching total users:', error);
                setError(`Error fetching total users: ${error.message}`);
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    if (loading) return <div>Loading...</div>;
    if (error) return <div>{error}</div>;

    return (
        <div className="page-content">
            <h1 className="page-title">Dashboard</h1>
            <div className="cards-container">
                <div className="card">
                    <h2>Total Number of Users</h2>
                    <p>{totalUsers}</p>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;