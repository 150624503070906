import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../styles/UserInfo.css';

const UserInfo = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get('https://admin.minbertv.com/api/users');
        setUsers(response.data);
      } catch (error) {
        console.error('Error fetching users:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  const handleDelete = async (userId) => {
    if (window.confirm('Are you sure you want to delete this user?')) {
      const originalUsers = [...users]; // Save original users for rollback
      setUsers(users.filter((user) => user._id !== userId)); // Optimistically update UI

      try {
        await axios.delete(`http://admin.minbertv.com/api/users/${userId}`);
      } catch (error) {
        console.error('Error deleting user:', error);
        setUsers(originalUsers); // Rollback to original state if error occurs
        alert('Failed to delete user. Please try again.'); // Notify user
      }
    }
  };

  if (loading) {
    return <div className="loading">Loading...</div>;
  }

  return (
    <div className="user-info-container">
      <h1 className="title">User Information</h1>
      <table className="user-table">
        <thead>
          <tr>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Phone Number</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr key={user._id}>
              <td>{user.firstName}</td>
              <td>{user.lastName}</td>
              <td>{user.phoneNumber}</td>
              <td>
                <button className="delete-button" onClick={() => handleDelete(user._id)}>
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default UserInfo;
