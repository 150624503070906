import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/Navigation.css'; // Import the CSS file
import logo from '../assets/logo.jpg'; // Import logo image from assets

function Navigation() {
  return (
    <div className="sidebar">
      <div className="sidebar-header">
        <img src={logo} alt="Company Logo" className="logo" /> {/* Use the imported logo */}
        <h2 className="company-name">MyApp</h2> {/* Company name */}
      </div>
      <ul className="nav-list">
        <li className="nav-item">
          <Link to="/" className="nav-link">Dashboard</Link>
        </li>
        <li className="nav-item">
          <Link to="/analytics" className="nav-link">Analytics & Reports</Link>
        </li>
        <li className="nav-item">
          <Link to="/notifications" className="nav-link">Notifications</Link>
        </li>
        <li className="nav-item">
          <Link to="/faqs" className="nav-link">Faqs</Link>
        </li>
        <li className="nav-item">
          <Link to="/user-info" className="nav-link">User Info</Link>
        </li>
        <li className="nav-item">
          <Link to="/videos" className="nav-link">Videos</Link>
        </li>
        <li className="nav-item">
          <Link to="/maintenance" className="nav-link">Maintenance</Link> {/* Add link for maintenance */}
        </li>
      </ul>
    </div>
  );
}

export default Navigation;
