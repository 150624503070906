// src/components/Notifications.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../styles/Notifications.css';

const Notifications = () => {
  const [notifications, setNotifications] = useState([]);
  const [newNotification, setNewNotification] = useState({ title: '', remark: '', duration: '' });

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await axios.get('https://admin.minbertv.com/api/notifications');
        setNotifications(response.data);
      } catch (error) {
        console.error('Error fetching notifications:', error);
      }
    };

    fetchNotifications();
  }, []);

  const handleChange = (e) => {
    setNewNotification({
      ...newNotification,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://admin.minbertv.com/api/notifications', newNotification);
      setNotifications([...notifications, response.data]);
      setNewNotification({ title: '', remark: '', duration: '' });
    } catch (error) {
      console.error('Error adding notification:', error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`https://admin.minbertv.com/api/notifications/${id}`);
      setNotifications(notifications.filter(notification => notification._id !== id));
    } catch (error) {
      console.error('Error deleting notification:', error);
    }
  };

  return (
    <div>
      <h2>Notifications</h2>
      
      {/* Form to add new notification */}
      <form onSubmit={handleSubmit}>
        <div>
          <label>Title:</label>
          <input
            type="text"
            name="title"
            value={newNotification.title}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Remark:</label>
          <textarea
            name="remark"
            value={newNotification.remark}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Duration (in seconds):</label>
          <input
            type="number"
            name="duration"
            value={newNotification.duration}
            onChange={handleChange}
            required
          />
        </div>
        <button type="submit">Add Notification</button>
      </form>

      {/* List of notifications */}
      <ul>
        {notifications.map(notification => (
          <li key={notification._id}>
            <h3>{notification.title}</h3>
            <p>{notification.remark}</p>
            <p>Duration: {notification.duration} seconds</p>
            <button onClick={() => handleDelete(notification._id)}>Delete</button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Notifications;
