import React, { useState, useEffect } from 'react';

const Maintenance = () => {
    const [isMaintenance, setIsMaintenance] = useState(false);

    // Fetch the initial maintenance mode status
    useEffect(() => {
        const fetchMaintenanceMode = async () => {
            try {
                const response = await fetch('https://admin.minbertv.com/api/maintenance');
                if (!response.ok) throw new Error('Network response was not ok');
                const data = await response.json();
                setIsMaintenance(data.maintenanceMode);
            } catch (error) {
                console.error('Error fetching maintenance mode status:', error);
            }
        };

        fetchMaintenanceMode();
    }, []);

    const toggleMaintenance = async () => {
        try {
            const response = await fetch('https://admin.minbertv.com/api/maintenance', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            const data = await response.json();
            setIsMaintenance(data.maintenanceMode);
            alert(`Maintenance mode is now ${data.maintenanceMode ? 'enabled' : 'disabled'}`);
        } catch (error) {
            console.error('Error toggling maintenance mode:', error);
        }
    };

    return (
        <div>
            <h1>Maintenance</h1>
            <button onClick={toggleMaintenance}>
                {isMaintenance ? 'Disable Maintenance Mode' : 'Enable Maintenance Mode'}
            </button>
        </div>
    );
};

export default Maintenance;
