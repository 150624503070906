import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import '../styles/ShowDetails.css';

const ShowDetails = () => {
    const { title } = useParams();
    const [show, setShow] = useState(null);
    const [error, setError] = useState(null);
    const [comment, setComment] = useState('');
    
    useEffect(() => {
        const fetchShowDetails = async () => {
            try {
                const response = await fetch(`http://localhost:5000/api/shows/${title}`);
                if (!response.ok) throw new Error('Network response was not ok');
                const showData = await response.json();
                setShow(showData);
            } catch (error) {
                setError(`Error fetching show details: ${error.message}`);
            }
        };

        fetchShowDetails();
    }, [title]);

    const handleCommentSubmit = async (e) => {
        e.preventDefault();
        if (comment.trim() === '') return;
        
        // Here you would handle posting the comment to the backend
        // For demonstration, we will log it
        console.log(`New comment on ${title}: ${comment}`);
        setComment(''); // Reset the comment input
    };

    if (error) return <div>{error}</div>;
    if (!show) return <div>Loading...</div>;

    // Sort comments in descending order based on a simple timestamp (for example, if you had a date field)
    const sortedComments = show.comments.sort((a, b) => b.timestamp - a.timestamp); // Assuming comments have a timestamp

    return (
        <div className="show-details">
            <h1>{show.title}</h1>
            <p>Views: {show.views}</p>
            <p>Likes: {show.likes}</p>
            <h2>Comments:</h2>
            {sortedComments.length > 0 ? (
                sortedComments.map((c, index) => (
                    <div key={index}>
                        <p><strong>{c.user}:</strong> {c.text}</p>
                    </div>
                ))
            ) : (
                <p>No comments yet.</p>
            )}
            <form onSubmit={handleCommentSubmit}>
                <input 
                    type="text" 
                    value={comment} 
                    onChange={(e) => setComment(e.target.value)} 
                    placeholder="Add a comment" 
                />
                <button type="submit">Submit</button>
            </form>
        </div>
    );
};

export default ShowDetails;
